<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h3>
        {{ Object.entries(this.data).length === 0 ? "Add New" : "Update" }} Fast
        Check
      </h3>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new"
      :settings="settings"
      :key="$vs.rtl"
    >
      <div class="p-6">
        <div class=" w-full">
          <label class="block mb-2">Fast Checks</label>
          <v-select
            :options="fastChecksOptionsData"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="fastCheckFilterData"
            class="mb-4 md:mb-0"
          />
        </div>
        <div class="mt-5 w-full">
          <label class="block mb-2">Rating</label>
          <ul class="demo-alignment">
            <li>
              <vs-radio vs-name="dataRating" v-model="dataRating" vs-value="1"
                >1</vs-radio
              >
            </li>
            <li>
              <vs-radio vs-name="dataRating" v-model="dataRating" vs-value="2"
                >2</vs-radio
              >
            </li>
            <li>
              <vs-radio vs-name="dataRating" v-model="dataRating" vs-value="3"
                >3</vs-radio
              >
            </li>
            <li>
              <vs-radio vs-name="dataRating" v-model="dataRating" vs-value="4"
                >4</vs-radio
              >
            </li>
            <li>
              <vs-radio vs-name="dataRating" v-model="dataRating" vs-value="5"
                >5</vs-radio
              >
            </li>
          </ul>
        </div>
        <!-- NAME -->
        <vs-input
          label="Name"
          v-model="name"
          class="mt-5 w-full"
          name="name"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('name')">{{
          errors.first("name")
        }}</span>

        <!-- NAME -->
        <vs-input
          label="Link"
          v-model="link"
          class="mt-5 w-full"
          name="link"
          v-validate="'url'"
        />
        <span class="text-danger text-sm" v-show="errors.has('link')">{{
          errors.first("link")
        }}</span>

        <input
          type="file"
          class="hidden"
          ref="uploadImgInput"
          @change="updateCurrImg"
          accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf"
        />
        <vs-button class="mt-5" href="#" @click="$refs.uploadImgInput.click()"
          >Upload Document</vs-button
        >
        <br/>
        <span class="text-sm" v-show="documentName!=null">{{
          this.documentName
        }}</span>
        <!-- <input class="mt-5 w-full" type="file" > -->
        <a
          v-if="documentUrl && documentUrlLoad"
          :href="documentUrl"
          target="_blank"
          class="mt-5 block"
          >View Document</a
        >
        <br />
        <!--<input type="file" v-model="document" class="mt-5 w-full" name="item-name" v-validate="'required'"/>-->
        <span class="text-danger text-sm" v-show="errors.has('document')">{{
          errors.first("document")
        }}</span>

        <div class="mt-5 w-full">
          <label class="block mb-2">Description</label>
          <vs-textarea
            class="w-full"
            name="description"
            v-model="description"
            v-validate="'required'"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('description')"
            >{{ errors.first("description") }}</span
          >
        </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid"
        >Submit</vs-button
      >
      <vs-button
        class="vs-button-secondary"
        @click="isSidebarActiveLocal = false"
        >Cancel</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import axios from "../../../../http/axios/index.js";
import vSelect from "vue-select";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dataId: null,
      resourceId: null,
      documentUrlLoad: true,
      name: "",
      link: "",
      document: null,
      documentUrl: null,
      documentName: null,
      description: "",
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },

      dataRating: null,
      oldRating: null,
      fastChecksOptionsData: [],
      fastCheckFilterData: {}
    };
  },
  props: {
    rating: {
      type: String,
      required: true
    },
    fastChecksOptions: {
      type: Array,
      required: true
    },
    fastCheckFilter: {
      type: Object,
      required: true
    },
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    isSidebarActive(val) {
      this.dataRating = this.rating;
      this.oldRating = this.rating;
      this.fastChecksOptionsData = this.$store.state.fastCheck.fastChecksOptions;
      //this.fastChecksOptionsData = this.fastChecksOptions;
      this.fastCheckFilterData = this.fastCheckFilter;
      this.$refs.uploadImgInput.value = null;
      this.documentUrlLoad = true;

      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        this.dataId = this.data._id;
        this.resourceId = this.data._id;
        this.name = this.data.title;
        this.link = this.data.link;
        this.documentUrl = this.data.document;
        this.description = this.data.description;
        this.initValues();
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },

  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },
    isFormValid() {
      return this.name && this.description;
    }
  },
  methods: {
    ...mapActions("fastCheck", [
      "updateFastCheckDevelopment",
      "storeFastCheckDevelopment"
    ]),
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.document = input.target.files[0];
        this.documentName = input.target.files[0].name;
        var reader = new FileReader();
        reader.onload = e => {
          this.documentUrl = e.target.result;
          this.documentUrlLoad = false;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    initValues() {
      if (this.data._id) return;
      this.dataId = null;
      this.name = "";
      this.link = "";
      this.documentUrl = null;
      this.description = "";
    },
    async submitData() {
      await this.$validator.validateAll().then(result => {
        if (result) {
          if (this.documentUrl == null && !this.link) {
            this.showMessage(
              "Error",
              "Please provide document or link.",
              "danger"
            );
            return;
          }
          let data = new FormData();
          data.append("fastCheckId", this.fastCheckFilterData.value);
          data.append("resourceId", this.resourceId);
          data.append("rating", parseInt(this.dataRating));
          data.append("name", this.name);
          data.append("document", this.document);
          data.append("link", this.link);
          data.append("description", this.description);
          data.append("oldRating", this.oldRating);

          this.$vs.loading();
          if (this.dataId !== null) {
            this.updateFastCheckDevelopment(data)
              .then(res => {
                this.$emit("refreshData", true);
                this.$emit("closeSidebar");
                this.initValues();
                this.errors.clear();
                this.$nextTick(() => {
                  this.$validator.reset();
                });

                this.$vs.loading.close();
                this.showMessage(
                  "Success",
                  "Fast check Updated Successfully.",
                  "success"
                );
              })
              .catch(err => {
                this.$vs.loading.close();
                console.error(err);
              });
          } else {
            this.storeFastCheckDevelopment(data)
              .then(res => {
                this.$vs.loading.close();
                this.$emit("refreshData", true);
                this.$emit("closeSidebar");
                this.initValues();

                this.errors.clear();
                this.$nextTick(() => {
                  this.$validator.reset();
                });
                this.showMessage(
                  "Success",
                  "Fast Check added Successfully.",
                  "success"
                );
              })
              .catch(err => {
                this.$vs.loading.close();
                console.error(err);
              });
          }
        }
      });
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
